import React from 'react';
import styled from 'styled-components';
import HIW1 from '../../../Images/Shipping/mensajeros-step-1.png';
import HIW2 from '../../../Images/Shipping/mensajeros-step-2.png';
import HIW3 from '../../../Images/Shipping/mensajeros-step-3.png';
import arrows from '../../../Images/arrows.png';
import { eiyaColor as colors } from '../../../utils/colors';
/* import { ButtonBPurple } from '../../ButtonElement';
import { getUrlCampaignData } from '../../../utils/helpFunctions'; */

const innerHeight = window.innerHeight + 60;
const realHeight = `${innerHeight}px`;

const SectionWraper = styled.div`
  background: url(${arrows});
  background-size: contain;
  background-position-y: bottom;
  height: ${realHeight};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 9rem;

  @media screen and (max-width: 1430px) {
    padding: 0 6rem;
  }

  @media screen and (max-width: 1330px) {
    padding: 0 3rem;
    background-position-y: center;
  }

  @media screen and (max-width: 1230px) {
    padding: 0 3rem;
    background-position-y: center;
}

  @media screen and (max-width: 1170px) {
    padding: 0 1rem;
    background-size: 120%;
    background-position: center;
  }

  @media screen and (max-width: 900px) {
    background: ${colors.purplite};
    padding: 2rem;
    height: 100%;
    width: 100%;
  }
`;

const SectionTitle = styled.p`
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
  color: ${colors.eiyaPurple};
  margin-bottom: 6rem;

  @media screen and (max-width: 1430px) {
  }

  @media screen and (max-width: 1330px) {
    margin-bottom: 5rem;
  }

  @media screen and (max-width: 1230px) {
    margin-bottom: 3rem;
  }

  @media screen and (max-width: 1170px) {
    margin-bottom: 3rem;
  }

  @media screen and (max-width: 900px) {
    margin-bottom: 3rem;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
  }
`;

const SectionContentWraper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 6rem;

  @media screen and (max-width: 1430px) {
  }

  @media screen and (max-width: 1330px) {
    margin-bottom: 5rem;
  }

  @media screen and (max-width: 1230px) {
  }

  @media screen and (max-width: 1170px) {
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

const SectionContentCard1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;

  @media screen and (max-width: 1430px) {
  }

  @media screen and (max-width: 1330px) {
  }

  @media screen and (max-width: 1230px) {
  }

  @media screen and (max-width: 1170px) {
  }

  @media screen and (max-width: 900px) {
    align-items: center;
    margin-bottom: 3rem;
  }
`;

const SectionContentCard2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1430px) {
  }

  @media screen and (max-width: 1330px) {
  }

  @media screen and (max-width: 1230px) {
  }

  @media screen and (max-width: 1170px) {
  }

  @media screen and (max-width: 900px) {
    align-items: center;
    margin-bottom: 3rem;
  }
`;

const SectionContentCard3 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;

  @media screen and (max-width: 1430px) {
  }

  @media screen and (max-width: 1330px) {
  }

  @media screen and (max-width: 1230px) {
  }

  @media screen and (max-width: 1170px) {
  }

  @media screen and (max-width: 900px) {
    align-items: center;
    margin-bottom: 3rem;
  }
`;

const SectionContentImg = styled.img`
  width: 50%;
  z-index: 2;
  margin-bottom: 2rem;
`;

const SectionContentDescription = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.20000000298023224px;
  
  @media screen and (max-width: 900px) {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.20000000298023224px;
  }
`;

function FirstSection() {
  return (
    <SectionWraper>
      <SectionTitle>¿Cómo funciona el servicio de Envíos?</SectionTitle>
      <SectionContentWraper>
        <SectionContentCard1>
          <SectionContentImg src={HIW1} alt="cuenta" />
          <SectionContentDescription style={{ paddingLeft: '2rem' }}>
            Crea tu cuenta <br />
            y carga saldo
          </SectionContentDescription>
        </SectionContentCard1>
        <SectionContentCard2>
          <SectionContentImg src={HIW2} alt="cuenta" />
          <SectionContentDescription>
            Crea, imprime y pega las
            <br />
            guías de tus paquetes
          </SectionContentDescription>
        </SectionContentCard2>
        <SectionContentCard3>
          <SectionContentImg src={HIW3} alt="cuenta" />
          <SectionContentDescription>
            Agenda una recolecta y<br />
            nosotros hacemos el resto
            <p style={{ fontSize: '14px' }}>* Recolectas disponibles en CDMX/ZM y GDL</p>
          </SectionContentDescription>
        </SectionContentCard3>
      </SectionContentWraper>
      {/* <ButtonBPurple onClick={() => window.open(getUrlCampaignData(), '_self')}>
        Regístrate aquí
      </ButtonBPurple> */}
    </SectionWraper>
  );
}

export default FirstSection;
