import React from 'react';
import styled from 'styled-components';
import { FaBox, FaBicycle, FaMoneyCheckAlt } from "react-icons/fa";
import { eiyaColor as colors } from '../../../utils/colors';
/* import { getUrlCampaignData } from '../../../utils/helpFunctions'; */
/* import { ButtonBPurple } from '../../ButtonElement'; */
import { ButtonAPurple } from '../../ButtonElement';
import Envios from '../../../Images/Home/envios.svg';
import Fulfillment from '../../../Images/Home/fulfillment.svg';
import Contraentrega from '../../../Images/Home/pago_contra_entrega.png';
import './styleCard.css';

export const Section = styled.div`
  height: ${window.innerHeight - 150}px;
  padding: 3rem 9rem 3rem 9rem;

  @media screen and (max-width: 1430px) {
    padding: 3rem 6rem 3rem 6rem;
  }

  @media screen and (max-width: 1650px) {
    height: ${window.innerHeight}px;
    padding: 4rem;
  }

  @media screen and (max-width: 1330px) {
    height: ${window.innerHeight}px;
    padding: 1rem 3rem;
  }

  @media screen and (max-width: 1230px) {
    padding: 2rem 1rem;
  }

  @media screen and (max-width: 1170px) {
  }

  @media screen and (max-width: 900px) {
    padding: 1rem;
    height: auto;
  }  
`;

export const InfoWraper = styled.div`
  padding: 1rem 6rem;
  
  @media screen and (max-width: 900px) {
    padding: 1rem;
    margin-bottom: 1rem;
  }
`;

export const InfoTitle = styled.p`
  color: ${colors.textDark};
  font-size: 24px;
  font-weight: 700;
  line-height: 24.2px;
  letter-spacing: 0em;
  text-align: center;

  @media screen and (max-width: 900px) {
    font-size: 18px;
    font-weight: 700;
    line-height: 16.8px;
  }
`;

export const InfoSubTitle = styled.p`
  color: ${colors.textDark};
  font-size: 18px;
  font-weight: 400;
  line-height: 21.78px;
  letter-spacing: 0em;
  text-align: center;
  margin: 1rem 0 2rem 0;

  @media screen and (max-width: 900px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
  }
`;

export const ColumnWraper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 900px) {
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  background: #7d5cc6;
  border-radius: 0 10px 10px 0;
  padding: 20px;
`;

const DescriptionBack = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: #eeeeee;
  padding: 20px;
  color: #424242;
`;

const BulletList = styled.ul`
  padding-left: 20px;
`;

const Bullet = styled.li`
  margin-bottom: 8px;

  @media screen and (max-width: 900px) {
    margin-bottom: 3px;
  }  
`;

const WhiteTitle = styled.p`
  font-size: 20px;
  font-weight: 700;
  line-height: 24.2px;
  text-align: left;
  color: #fafafa;
  margin: 5px 0 10px 0;
`;

const WhiteParagraph = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  color: #fafafa;
`;

const WhiteCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 28px;
  padding: 0 15rem;

  @media screen and (max-width: 900px) {
    margin-top: 20px;
    padding: 0;
  }
}
`;

const ImageCard = styled.img`
  @media screen and (max-width: 900px) {
    height: 210px;
  } 
`;

const DetailText = styled.p`
  font-size: 10px;
  margin-top: 10px;
`;

export default function InfoSection() {
  return (
    <Section>
      <InfoWraper>
        <ColumnWraper>
          <InfoTitle>
            Nuestros servicios
          </InfoTitle>
          <InfoSubTitle>¡Haz que tu marca crezca mientras nosotros recorremos la última milla!</InfoSubTitle>
          <CardContainer>
            <div className="flip-container">
              <div className="card">
                <div className="front" >
                  <ImageCard src={Envios} alt="Envíos" />
                  <Description>
                    <WhiteCircle>
                      <FaBicycle style={{ fontSize: '20px', color: colors.eiyaPurple }} />
                    </WhiteCircle>
                    <WhiteTitle>Envios / Paqueterias</WhiteTitle>
                    <WhiteParagraph>
                      Entregas garantizadas, rápidas y eficientes mientras cuidamos el medio ambiente. 
                    </WhiteParagraph>
                  </Description>
                </div>
                <div className="back">
                  <DescriptionBack>
                    <WhiteTitle style={{ color: `${colors.eiyaPurple}` }}>Envios / Paqueterias</WhiteTitle>
                    <BulletList>
                      <Bullet>Crea tus guías y agenda la recolecta</Bullet>
                      <Bullet>Entregas en horas, mismo día, día siguiente*</Bullet>
                      <Bullet>Administra tus envios fácil y seguro</Bullet>
                    </BulletList>
                    <DetailText>
                      * Recolectas disponibles en CDMX/ZM y GDL, aplica para determinados códigos postales
                    </DetailText>
                  </DescriptionBack>
                </div>
              </div>
            </div>
            <div className="flip-container">
              <div className="card">
                <div className="front" >
                  <ImageCard src={Contraentrega} alt="Pago contra entrega" />
                  <Description>
                    <WhiteCircle>
                      <FaMoneyCheckAlt style={{ fontSize: '20px', color: colors.eiyaPurple }} />
                    </WhiteCircle>
                    <WhiteTitle>Pago Contra Entrega</WhiteTitle>
                    <WhiteParagraph>
                      Entregamos y cobramos por ti de una manera fácil y segura
                    </WhiteParagraph>
                  </Description>
                </div>
                <div className="back">
                  <DescriptionBack>
                    <WhiteTitle style={{ color: `${colors.eiyaPurple}` }}>Pago Contra Entrega</WhiteTitle>
                    <BulletList>
                      <Bullet>Atrae nuevos clientes con esta forma de pago</Bullet>
                      <Bullet>Recibe el dinero de forma segura</Bullet>
                      <Bullet>Genera confianza en tu cliente: paga cuando recibe</Bullet>
                    </BulletList>
                    <DetailText>
                      Recolecta y entrega en CDMX. Recolecta y entrega en Guadalajara.
                    </DetailText>
                  </DescriptionBack>
                </div>
              </div>
            </div>
            <div className="flip-container">
              <div className="card">
                <div className="front" >
                  <ImageCard src={Fulfillment} alt="Micro-Fulfillment" />
                  <Description>
                    <WhiteCircle>
                      <FaBox style={{ fontSize: '20px', color: colors.eiyaPurple }} />
                    </WhiteCircle>
                    <WhiteTitle>Micro-Fulfillment</WhiteTitle>
                    <WhiteParagraph>
                      Almacenamos, alistamos y despachamos tus pedidos.
                    </WhiteParagraph>
                  </Description>
                </div>
                <div className="back">
                  <DescriptionBack>
                    <WhiteTitle style={{ color: `${colors.eiyaPurple}` }}>Micro-Fulfillment</WhiteTitle>
                    <BulletList>
                      <Bullet>Almacenamiento, alistamiento y despacho de productos</Bullet>
                      <Bullet>Bodegas estratégicamente situadas para llegar más rápido a tus clientes</Bullet>
                    </BulletList>
                    <DetailText>Puntos de almacenaje en CDMX y Guadalajara.</DetailText>
                  </DescriptionBack>
                </div>
              </div>
            </div>
          </CardContainer>
          <ButtonsContainer>
            <ButtonAPurple target="_blank" rel="noreferrer" href="https://api.whatsapp.com/send?phone=525659157862">
              Contáctanos
            </ButtonAPurple>
            {/* <ButtonBPurple onClick={() => window.open(getUrlCampaignData(), '_self')}>
              ¡Registrate Ahora!
            </ButtonBPurple> */}
          </ButtonsContainer>
        </ColumnWraper>
      </InfoWraper>
    </Section>
  );
}
