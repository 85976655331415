import React from 'react';
import styled from 'styled-components';
import { eiyaColor as colors } from '../../../utils/colors';
import background from '../../../Images/About/conocenos-footer.png';
/* import { ButtonBYellow } from '../../ButtonElement';
import { getUrlCampaignData } from '../../../utils/helpFunctions'; */

const BackgroundGradient = styled.div`
  background: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 900px) {
    padding: 3rem 1rem;
    height: auto;
    align-items: baseline;
  }
`;

const SectionTitle = styled.p`
  font-size: 24px;
  color: ${colors.white};
  font-weight: 800;
  line-height: 28.8px;
  margin-bottom: 2rem;
`;

const SectionDescription = styled.p`
  font-size: 20px;
  color: ${colors.white};
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 2rem;
`;

export default function AditionalInfo() {
  return (
    <BackgroundGradient>
      <SectionTitle>Mejorar los envíos de tu tienda en línea</SectionTitle>
      <SectionDescription>Crea tu cuenta y envía tus pedidos hoy mismo</SectionDescription>
      {/* <ButtonBYellow onClick={() => window.open(getUrlCampaignData(), '_self')}>
        Crea tu cuenta gratis
      </ButtonBYellow> */}
    </BackgroundGradient>
  );
}



/* import React from 'react';
import styled from 'styled-components';
import { FaHeadset } from 'react-icons/fa';
import { eiyaColor as colors } from '../../../utils/colors';
import background from '../../../Images/About/conocenos-footer.png';
import { ButtonAWhite, ButtonAYellow } from '../../ButtonElement';

const SectionWraper = styled.div`
  height: ${window.innerHeight}px;
  display: flex;
  flex-direction: column;
  background: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  padding: 9rem;
  height: 500px;

  @media screen and (max-width: 900px) {
    padding: 3rem 1rem;
    height: auto;
  }
`;

const SectionTitle = styled.p`
  font-size: 24px;
  color: ${colors.white};
  font-weight: 700;
  line-height: 28.8px;
  margin-bottom: 2rem;
  display: inherit;
  align-self: baseline;

  @media screen and (max-width: 900px) {
    margin-left: 0;
    text-align: center;
  }
`;

const SectionContentWraper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: baseline;
  }
`;

const SectionContentContainer1 = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 900px) {
    margin-bottom: 2rem;
  }
`;

const SectionContentContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

const SectionContent = styled.p`
  color: ${colors.white};
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  margin-bottom: 2rem;
`;

const SectionDivider = styled.div`
  border: 1px solid #ffffff;
  transform: rotate(90deg);
  opacity: 0.2;
  width: 95px;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export default function AditionalInfo() {
  return (
    <SectionWraper>
      <SectionTitle>
        Eiya es una plataforma de servicios logísticos y tecnológicos para marcas con venta en línea
      </SectionTitle>
      <SectionContentWraper>
        <SectionContentContainer1>
          <SectionContent>
            Para comenzar a enviar tus pedidos HOY; <br />
            regístrate aquí.
          </SectionContent>
          <ButtonAYellow href="https://envios.eiya.mx/signup">Crea tu cuenta gratis</ButtonAYellow>
        </SectionContentContainer1>
        <SectionDivider />
        <SectionContentContainer2>
          <SectionContent>
            Para mejorar tu logística actual y dedicarte al crecimiento de tu negocio, utiliza
            nuestro servicio Fulfillment contactando a un asesor de ventas.
          </SectionContent>
          <ButtonAWhite href="https://meetings.hubspot.com/ulises-garduno/home" target="_blank">
            <FaHeadset style={{ color: colors.eiyaPurple, marginRight: '1rem' }} />
            Agendar llamada
          </ButtonAWhite>
        </SectionContentContainer2>
      </SectionContentWraper>
    </SectionWraper>
  );
} */
