/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { NavLink as NavLinks } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Tooltip } from "@material-ui/core";
import { FaBars } from "react-icons/fa";
import { FaWhatsapp } from 'react-icons/fa';
import { animateScroll as scroll } from "react-scroll";
import styled from "styled-components";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled as MUIStyled } from "@material-ui/core/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Logo from "../../Images/eiya-logo.svg";
import { eiyaColor as colors } from "../../utils/colors";
import {
  Nav,
  NavbarContainer,
  MobileIcon,
  NavMenu,
  NavItem,
  /*   NavLinks,
   */ NavBtn,
  NavBtnLink,
  NavLogoImg,
  NavBtnLinks,
} from "./NavbarElements";
/* import { ButtonBPurple } from "../ButtonElement";
import { getUrlCampaignData } from "../../utils/helpFunctions"; */

const customTheme = createTheme({
  root: {
    fontFamily: "Lato",
    fontWeight: "500",
    fontSize: "16",
    textTransform: "none",
    color: colors.textDark,
  },
  typography: {
    fontFamily: "Lato",
    fontWeight: "500",
    fontSize: "16",
    textTransform: "none",
    color: colors.textDark,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Lato';
          font-size: '16';
          font-weight: 500;
          color: colors.textDark,
          src: local('Lato'), local('Lato-Regular'), url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap'}) format('lato');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

export const NavMobileContainer = styled.div`
  display: none;

  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
`;

export const NavLink = styled.div`
  color: ${colors.textDark};
  font-size: 1rem;
  display: flex;
  align-items: center;
  height: 100%;
`;

export const NavButton = MUIStyled(Button)`
  color: ${colors.textDark};
  font-size: 1rem;
  text-transform: none;
  transition: none;

  &:hover{
    background: ${colors.white};
  }

  &:focus-visible {
    outline: none; 
  }
`;

export const DDLComponent = MUIStyled(Menu)`
  transition: none; 
  outline: none;
`;

export const Line = styled.div`
  width: 24px;
  height: 0px;
  opacity: 0.5;
  border: 1px solid #757575;
  transform: rotate(90deg);
  background: ${colors.textBody};
`;

export const Question = styled.p`
  color: ${colors.textBody};

  @media screen and (max-width: 1090px) {
    display: none;
  }
`;

export const InfoWraper = styled.div`
  background: ${colors.eiyaPurple};
  color: ${colors.white};
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center; 
  
  @media screen and (max-width: 900px) {
    height: unset;
    padding: 1rem;
  }
`;

export const InfoText = styled.p`
    color: ${colors.white};
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;

  @media screen and (max-width: 1090px) {

}
`;

function Navbar({ toggle }) {
  const [scrollNav, setScrollNav] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <InfoWraper>
        <InfoText>✨ <span style={{ color: colors.accentYellow }}>¡Nuevo servicio!</span> ✨ Pago contra entrega, ya disponible 💵 📦 😎 <a href="https://api.whatsapp.com/send?phone=525659157862" target="_blank" rel="noreferrer" style={{ color: colors.white, fontWeight: '500' }}>agenda llamada</a> para saber más.</InfoText>
      </InfoWraper>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavMobileContainer>
            <NavLogoImg src={Logo} onClick={toggleHome} />
            <Tooltip title="Atención a clientes">
              <a 
                href="https://api.whatsapp.com/send?phone=525659157862"
                target="_blank"
                rel="noreferrer"
              >
                <FaWhatsapp style={{ color: colors.greenWA, fontSize: '20px'  }} />
              </a>
            </Tooltip>
            <Question></Question>
            <NavBtnLink
              href="https://envios.eiya.mx/login"
              style={{ background: colors.white, color: colors.textDark }}
            >
              Iniciar sesión
            </NavBtnLink>
            {/* <ButtonBPurple
              onClick={() => window.open(getUrlCampaignData(), '_self')}
              smooth
              duration={500}
              spy
              exact
              offset={-80}
            >
              Regístrate
            </ButtonBPurple> */}
            <MobileIcon onClick={toggle}>
              <FaBars />
            </MobileIcon>
          </NavMobileContainer>
          <NavMenu>
            <NavLogoImg src={Logo} />
            <NavItem onClick={toggleHome}>
              <NavLinks
                /* to="/envios" */
                to="/"
                smooth
                duration={500}
                spy
                exact
                offset={-80}
                style={({ isActive }) => ({
                  color: isActive ? colors.eiyaPurple : colors.textDark,
                  padding: "0 1rem",
                  height: "100%",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  borderBottom: isActive
                    ? `3px solid ${colors.eiyaPurple}`
                    : "none",
                })}
              >
                  Inicio {/* Envíos */}
              </NavLinks>
            </NavItem>
            {/* <NavItem onClick={toggleHome}>
              <NavLinks
                activeClassName="is-active"
                to="/fulfillment"
                smooth
                duration={500}
                spy
                exact
                offset={-80}
                style={({ isActive }) => ({
                  color: isActive ? colors.eiyaPurple : colors.textDark,
                  padding: "0 1rem",
                  height: "100%",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  borderBottom: isActive
                    ? `3px solid ${colors.eiyaPurple}`
                    : "none",
                })}
              >
                Fulfillment
              </NavLinks>
              </NavItem> */}
            <NavItem onClick={toggleHome}>
              <NavLinks
                activeClassName="is-active"
                to="/conocenos"
                smooth
                duration={500}
                spy
                exact
                offset={-80}
                style={({ isActive }) => ({
                  color: isActive ? colors.eiyaPurple : colors.textDark,
                  padding: "0 1rem",
                  height: "100%",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  borderBottom: isActive
                    ? `3px solid ${colors.eiyaPurple}`
                    : "none",
                })}
              >
                Conócenos
              </NavLinks>
            </NavItem>
            <NavItem onClick={toggleHome}>
              <NavLinks
                activeClassName="is-active"
                to="/mensajeros"
                smooth
                duration={500}
                spy
                exact
                offset={-80}
                style={({ isActive }) => ({
                  color: isActive ? colors.eiyaPurple : colors.textDark,
                  padding: "0 1rem",
                  height: "100%",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  textDecoration: "none",
                  borderBottom: isActive
                    ? `3px solid ${colors.eiyaPurple}`
                    : "none",
                })}
              >
                Mensajeros
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLink>
                <ThemeProvider theme={customTheme}>
                  <NavButton
                    sx={{
                      textTransform: "none",
                      color: colors.textDark,
                      fontSize: "12",
                    }}
                    endIcon={<KeyboardArrowDownIcon />}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    Recursos
                  </NavButton>
                  <DDLComponent
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {/* <a
                      style={{ textDecoration: "none", color: colors.textDark }}
                      href="https://www.tienda.eiya.mx/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <MenuItem onClick={handleClose}>Insumos</MenuItem>
                  </a> */}
                    <a
                      style={{ textDecoration: "none", color: colors.textDark }}
                      href="https://www.youtube.com/channel/UCdxznk4UEmDQTn2tN6Dy4JQ"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <MenuItem onClick={handleClose}>
                        Guías y tutoriales
                      </MenuItem>
                    </a>
                    <a
                      style={{ textDecoration: "none", color: colors.textDark }}
                      href="https://developers.eiya.mx/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <MenuItem onClick={handleClose}>Documentación API</MenuItem>
                    </a>
                    <a
                      style={{ textDecoration: "none", color: colors.textDark }}
                      href="https://ayuda.eiya.mx/hc/es-mx"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <MenuItem onClick={handleClose}>Centro de ayuda</MenuItem>
                    </a>
                  </DDLComponent>
                </ThemeProvider>
              </NavLink>
            </NavItem>
          </NavMenu>
          <NavBtn>
            <Tooltip title="Atención a clientes">
              <a 
                href="https://api.whatsapp.com/send?phone=525659157862"
                target="_blank"
                rel="noreferrer"
                style={{ margin: '0 1rem' }}
              >
                <FaWhatsapp style={{ color: colors.greenWA, fontSize: '20px' }} />
              </a>
            </Tooltip>
            <Question>¿Ya tienes cuenta?</Question>
            <NavBtnLinks href="https://envios.eiya.mx/login">
              Iniciar sesión
            </NavBtnLinks>
            {/* <Line />
            <ButtonBPurple
              onClick={() => window.open(getUrlCampaignData(), '_self')}
            >
              Regístrate
            </ButtonBPurple> */}
          </NavBtn>
        </NavbarContainer>
      </Nav>
    </>
  );
}

export default Navbar;
