import React from 'react';
import styled from 'styled-components';
import { eiyaColor as colors } from '../../../utils/colors';
import background from '../../../Images/Shipping/envios-footer.png';
/* import { ButtonBYellow } from '../../ButtonElement';
import { getUrlCampaignData } from '../../../utils/helpFunctions'; */

const BackgroundGradient = styled.div`
  background: url(${background});
  background-repeat: no-repeat;
  background-size: cover;
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 900px) {
    padding: 3rem 1rem;
    height: auto;
    align-items: baseline;
  }
`;

const SectionTitle = styled.p`
  font-size: 24px;
  color: ${colors.white};
  font-weight: 800;
  line-height: 28.8px;
  margin-bottom: 2rem;
`;

const SectionDescription = styled.p`
  font-size: 20px;
  color: ${colors.white};
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 2rem;
`;

export default function AditionalInfo() {
  return (
    <BackgroundGradient>
      <SectionTitle>Mejorar los envíos de tu tienda en línea</SectionTitle>
      <SectionDescription>Crea tu cuenta y envía tus pedidos hoy mismo</SectionDescription>
      {/* <ButtonBYellow onClick={() => window.open(getUrlCampaignData(), '_self')}>
        Crea tu cuenta gratis
      </ButtonBYellow> */}
    </BackgroundGradient>
  );
}
